import Repository from './Repository';
import {AxiosResponse} from "axios";
import {ThuocCreate} from "@/models/thuocCreate";
import {ThuocXuLy} from "@/models/thuocXuLy";
import {ThuocXuLyDetail} from "@/models/thuocXuLyDetail";

const resource = '/thuoc';

export default new class{
    createThuoc(thuocCreate: ThuocCreate): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/taothuoc`, thuocCreate);
    }
    getList(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/danhsach`);
    }
    exportList(thuocXuLy: ThuocXuLy[]): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/xuatdanhsach`, thuocXuLy);
    }
    getListThuocXL(timeFrom: number, timeTo: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/danhsachthuocxuly/${timeFrom}/${timeTo}`);
    }
    exportListStatistical(thuocXuLyDetails: ThuocXuLyDetail[]): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/xuatdanhsachthongke`, thuocXuLyDetails);
    }
    delete(id: number): Promise<AxiosResponse<any>>{
        return Repository.delete<any>(`${resource}/delete/${id}`);
    }
}
