

    import { computed, ref } from 'vue'
    import { useToast } from "primevue/usetoast";
    import { useRouter } from 'vue-router'
    import {useStore} from "vuex";
    import ThuocRepository from "@/services/ThuocRepository";
    import {ThuocXuLy} from "@/models/thuocXuLy";
    import {ThuocXuLyDetail} from "@/models/thuocXuLyDetail";
    import moment from 'moment';
    import axios from "axios";
    import {useConfirm} from "primevue/useconfirm";
    import router from "@/router";

    export default {

        setup(){
            const toast = useToast();
            const date = ref();
            const thuocSauXuLy = ref([] as ThuocXuLyDetail[]);
            const id = ref(0);
            const store = useStore();

          if(!(store.state.permission == 'admin')){
            router.push({
              name: 'home'
            });
          }
            const selectCalendar = () => {
                if((date.value[0] != null)&&(date.value[1] != null)){
                    ThuocRepository.getListThuocXL(date.value[0]/1000, date.value[1]/1000)
                        .then(response => {
                          id.value = 0;
                            thuocSauXuLy.value = response.data;
                            thuocSauXuLy.value.forEach(x =>{
                              x.idThuoc = x.id;
                              id.value++;
                              x.id = id.value
                            });
                            console.log("############$$$$$$$$$$$$$$$$$$$$$$$$$$$ selectCalendar thuocSauXuLy: " + JSON.stringify(thuocSauXuLy.value));
                        })
                        .catch(err => {
                            toast.add({
                                severity: 'error',
                                summary: 'Lỗi',
                                detail:"Không có dữ liệu với khoản thời gian lựa chọn.",
                                life: 2000
                            });
                        })
                        .finally(()=>{
                        });
                }
            };
            const clearCalendar = () => {
                date.value = new Date();
                thuocSauXuLy.value = [];
            };
            const formatDateTime = (date) => {
                return moment(String(date)).format('DD/MM/YYYY');
            };

            const exportFileDetail = ref(false);
            const fileNameExport = ref("");
            const loadingBar = ref(false);
            const exportFile = () => {
                loadingBar.value = true;
                exportFileDetail.value = false;
                ThuocRepository.exportListStatistical(thuocSauXuLy.value)
                    .then((response) => {
                        fileNameExport.value = response.data;
                        exportFileDetail.value = true;
                        loadingBar.value = false;
                        console.log("##########$$$$$$$$$$$$$$$$$$$$$$$ Thuoc: " + JSON.stringify(response.data));
                    })
                    .catch(err => {
                        loadingBar.value = false;
                        toast.add({
                            severity: 'error',
                            summary: 'Lỗi',
                            detail:err.response.data,
                            life: 2000
                        });
                    });
            }
            const downloadFile = () => {
                axios({
                    url: 'api/thuoc/taidanhsachthuoc/'+ fileNameExport.value,
                    method: 'GET',
                    responseType: 'blob',
                }).then((response) => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', fileNameExport.value);
                    document.body.appendChild(fileLink);

                    fileLink.click();
                })
                    .catch(err => {
                        toast.add({
                            severity: 'error',
                            summary: 'Lỗi',
                            detail:err.response.data,
                            life: 2500
                        })});
            };

            const deleteFile = (id) => {
              ThuocRepository.delete(id)
                  .then((response) => {
                    toast.add({
                      severity: 'success',
                      summary: 'Lỗi',
                      detail:'xóa dữ liệu thành công',
                      life: 2000
                    });
                    selectCalendar();
                  })
                  .catch(err => {
                    toast.add({
                      severity: 'error',
                      summary: 'Lỗi',
                      detail:err.response.data,
                      life: 2000
                    });
                  });
            }
          const confirm = useConfirm();
          const del = (event, id) => {
            confirm.require({
              target: event.currentTarget,
              message: 'Bạn có chắc muốn xoá tài khoản này không?',
              icon: 'pi pi-info-circle',
              acceptClass: 'p-button-danger',
              rejectClass: 'p-button-outlined',
              acceptLabel: 'Đồng ý',
              rejectLabel: 'Huỷ',
              accept: () => {
                deleteFile(id);
              },
              reject: () => {
              }
            });
          };

            return {
                date,
                thuocSauXuLy,
                selectCalendar,
                clearCalendar,
                formatDateTime,
                exportFile,
                downloadFile,
                loadingBar,
                exportFileDetail,
                deleteFile,
                 del,
            }
        }
    }

