

  import { computed, ref } from 'vue';
  import {useToast} from "primevue/usetoast";
  import moment from 'moment';
  import {FilterMatchMode, FilterOperator} from "primevue/api";
  import axios from "axios";
  import {ThuocCreate} from "@/models/thuocCreate";
  import ThuocRepository from "@/services/ThuocRepository";
  import {ThuocDetail} from "@/models/thuocDetail";
  import {ThuocXuLy} from "@/models/thuocXuLy";
  import {TyLe} from "@/models/tyLe";
  import router from "@/router";
  import store from "@/store";

  export default {
    setup() {
    const thuocCreate = ref({} as ThuocCreate);
    const toast = useToast();
    const thuocDetails = ref([] as ThuocDetail[]);
    const idThuoc = ref();
    const donGia = ref();
    const dsdonVi = ref([
        {label: "gam"},
        {label: "kg"}
        ]);
    const tinhTrang = ref(1);
    const tyle = ref("");
    const dsgiaiDoan = ref([] as {label: string, value: string, param: number}[]);
    const dsgiaiDoan1 = ref([
        {label: "Sơ chế", value: "Sơ chế", param: 1},
        {label: "Phức chế", value: "Phức chế", param: 2},
        {label: "Phương pháp khác", value: "Phương pháp khác", param: 3},
    ]);
      const dsgiaiDoan2 = ref([
        {label: "Sơ chế", value: "Sơ chế", param: 1},
        {label: "Sơ chế và Phức chế", value: "Phức chế", param: 2},
        {label: "Phương pháp khác", value: "Phương pháp khác", param: 3},
      ]);
        dsgiaiDoan.value = dsgiaiDoan2.value;
    const dsSoCheBanDau = ref([] as {label: string, value: string, param: number}[]);
    const dsSoChe = ref([
            {label: "Ngâm, ủ, rửa, phơi, sấy", value: "Sơ chế", param: 1},
            {label: "Thái phiến", value: "Sơ chế", param: 2},
            {label: "Sao vàng", value: "Phức chế", param: 3},
            {label: "Sao đen", value: "Phức chế", param: 4},
            {label: "Chích rượu, giấm, muối gừng, cam thảo, mật ong...", value: "Phức chế", param: 5},
        ]);
      const dsSoCheBD = ref([] as {label: string, value: string, param: number}[]);
        const dstinhTrang = ref([
            {label: "Thô", value: 1},
            {label: "Sơ chế", value: 2},
            {label: "Phức chế", value: 3},
        ]);
        const dsTinhToan = ref([] as {param: number; value: number}[]);
    const soChe = ref([] as {label: string; value: string, param: number}[]);
    const giaiDoan = ref(0);
    const khoiLuong = ref();
    const donvi = ref("gam");
    const thuchien = ref(0);
    const chiPhiNgoai = ref();
    const thuocSauXuLy = ref([] as ThuocXuLy[]);
    const id = ref(0);
    const exportFileDetail = ref(false);
    const fileNameExport = ref("");
    const loadingBar = ref(false);
    const scBanDau = ref(0);
    const tinhTrangPhucChe = ref(false);
    const tinhTrangBD = ref(false);

    const clearThuoc = () => {
    const temp = ref({} as ThuocCreate);
    thuocCreate.value = temp.value;
    }
    const boPhanDung =ref([
        {label: "Dùng dạng rễ, thân rễ, quả, hạt, vỏ (2%)"},
        {label: "Nhóm bộ phận dùng khác còn lại (3%)"},
    ]);
      if(store.state.token == ""){
        router.push({
          name: 'home'
        });
      };

      tyle.value = boPhanDung.value[0].label;
        const baoQuan = ref("");
        khoiLuong.value = null;
        chiPhiNgoai.value = null;

        dsTinhToan.value.push({param: 1,value:0});
        dsTinhToan.value.push({param: 2,value:0});
        dsTinhToan.value.push({param: 3,value:0});
        dsTinhToan.value.push({param: 4,value:0});
        dsTinhToan.value.push({param: 5,value:0});
        dsTinhToan.value.push({param: 6,value:0});
        ThuocRepository.getList()
            .then((response) => {
                console.log("##########$$$$$$$$$$$$$$$$$$$$$$$ Thuoc: " + JSON.stringify(response.data));
                thuocDetails.value = response.data;
            })
            .catch(err => {
                toast.add({
                    severity: 'error',
                    summary: 'Lỗi',
                    detail:err.response.data,
                    life: 2000
                });
            });

      const clear = () => {
        khoiLuong.value = null;
        donvi.value = "gam";
        giaiDoan.value = 0;
        thuchien.value = 0;
        chiPhiNgoai.value = null;
        donGia.value = null;
        idThuoc.value = "";
        tyle.value = boPhanDung.value[0].label;
        tinhTrang.value = 1;
        tinhTrangBD.value = false;
        scBanDau.value = 0;
        clearThuoc();
      }

        const tinhToan = (thuoc: ThuocXuLy) => {
          const h1 = ref(0);
          const h2 = ref(0);
          const temp = ref(0);
          const thuocDetail = ref({} as ThuocDetail);
          thuocDetails.value.forEach(x => {
            if (x.tenThuoc == thuoc.tenThuoc) thuocDetail.value = x;
          });
          dsTinhToan.value[0].value = thuocDetail.value.ngam;
          dsTinhToan.value[1].value = thuocDetail.value.thai;
          dsTinhToan.value[2].value = thuocDetail.value.saoVang;
          dsTinhToan.value[3].value = thuocDetail.value.saoDen;
          dsTinhToan.value[4].value = thuocDetail.value.chich;
          dsTinhToan.value[5].value = thuocDetail.value.phuongPhapKhac;
          thuoc.tongTienNhap = parseFloat((khoiLuong.value * donGia.value).toFixed(2));
          if (tyle.value == boPhanDung.value[0].label) {
            h2.value = 2;
          } else if (tyle.value == boPhanDung.value[1].label) {
            h2.value = 3;
          }
          thuoc.tiLeHuHaoBQ = h2.value;
          if (tinhTrang.value - 1 == giaiDoan.value) {
            h1.value = 0;
            thuoc.tiLeHuHaoCB = 0;
            thuoc.donGiaHT = parseFloat((((100 * donGia.value) / (100 - h2.value)) + chiPhiNgoai.value).toFixed(2));
            thuoc.soLuongHT = (khoiLuong.value * (100 - h2.value)) / 100;
            thuoc.tongTienHT = parseFloat((thuoc.donGiaHT * thuoc.soLuongHT).toFixed(2));
            id.value++;
            thuoc.id = id.value;
            thuocSauXuLy.value.push(thuoc);
            clear();
          } else {
            if ((scBanDau.value != 0) && (thuchien.value != 0)) {
              const a = ref(0);
              const b = ref(0);
              dsTinhToan.value.forEach(x => {
                if (x.param == thuchien.value) a.value = x.value;
              });
              dsTinhToan.value.forEach(x => {
                if (x.param == scBanDau.value) b.value = x.value;
              });
              if (a.value > b.value) {
                h1.value = a.value - b.value;
                thuoc.tiLeHuHaoCB = h1.value;
                thuoc.donGiaHT = parseFloat((((100 * donGia.value) / (100 - h1.value - h2.value)) + chiPhiNgoai.value).toFixed(2));
                thuoc.soLuongHT = (khoiLuong.value * (100 - h1.value - h2.value)) / 100;
                thuoc.tongTienHT = parseFloat((thuoc.donGiaHT * thuoc.soLuongHT).toFixed(2));
                id.value++;
                thuoc.id = id.value;
                thuocSauXuLy.value.push(thuoc);
                clear();
              } else {
                toast.add({
                  severity: 'error',
                  summary: 'Lỗi',
                  detail: "Vui lòng kiểm tra lại thông tin",
                  life: 2000
                });
              }
              // h1.value = dsTinhToan.value.filter(x => x.param == thuchien.value) - dsTinhToan.value.find(x => x.param == scBanDau.value).value;
            } else {
              if ((scBanDau.value == 0)) {
                if (tinhTrang.value == 1) {
                  if (thuchien.value == 0) {
                    if (giaiDoan.value == 3) {
                      const a = ref(dsTinhToan.value[5].value);
                      const b = ref(0);
                      dsTinhToan.value.forEach(x => {
                        if (x.param == scBanDau.value) b.value = x.value;
                      });
                      if (a.value >= b.value) {
                        h1.value = a.value - b.value;
                        thuoc.tiLeHuHaoCB = h1.value;
                        thuoc.donGiaHT = parseFloat((((100 * donGia.value) / (100 - h1.value - h2.value)) + chiPhiNgoai.value).toFixed(2));
                        thuoc.soLuongHT = (khoiLuong.value * (100 - h1.value - h2.value)) / 100;
                        thuoc.tongTienHT = parseFloat((thuoc.donGiaHT * thuoc.soLuongHT).toFixed(2));
                        id.value++;
                        thuoc.id = id.value;
                        thuocSauXuLy.value.push(thuoc);
                        clear();
                      } else {
                        toast.add({
                          severity: 'error',
                          summary: 'Lỗi',
                          detail: "Vui lòng kiểm tra lại thông tin",
                          life: 2000
                        });
                      }
                    } else {
                      toast.add({
                        severity: 'error',
                        summary: 'Lỗi',
                        detail: "Vui lòng kiểm tra lại thông tin",
                        life: 2000
                      });
                    }
                  }
                  else {
                    const a = ref(0);
                    const b = ref(0);
                    dsTinhToan.value.forEach(x => {
                      if (x.param == thuchien.value) a.value = x.value;
                    });
                    dsTinhToan.value.forEach(x => {
                      if (x.param == scBanDau.value) b.value = x.value;
                    });
                    if (a.value >= b.value) {
                      h1.value = a.value - b.value;
                      thuoc.tiLeHuHaoCB = h1.value;
                      thuoc.donGiaHT = parseFloat((((100 * donGia.value) / (100 - h1.value - h2.value)) + chiPhiNgoai.value).toFixed(2));
                      thuoc.soLuongHT = (khoiLuong.value * (100 - h1.value - h2.value)) / 100;
                      thuoc.tongTienHT = parseFloat((thuoc.donGiaHT * thuoc.soLuongHT).toFixed(2));
                      id.value++;
                      thuoc.id = id.value;
                      thuocSauXuLy.value.push(thuoc);
                      clear();
                    }
                  }
                } else {
                  toast.add({
                    severity: 'error',
                    summary: 'Lỗi',
                    detail: "Vui lòng kiểm tra lại thông tin",
                    life: 2000
                  });
                }
              }
              else if (thuchien.value == 0) {
                if (tinhTrang.value == 1) {
                  if (thuchien.value == 0) {
                    if (giaiDoan.value == 3) {
                      const a = ref(dsTinhToan.value[5].value);
                      const b = ref(0);
                      dsTinhToan.value.forEach(x => {
                        if (x.param == scBanDau.value) b.value = x.value;
                      });
                      if (a.value > b.value) {
                        h1.value = a.value - b.value;
                        thuoc.tiLeHuHaoCB = h1.value;
                        thuoc.donGiaHT = parseFloat((((100 * donGia.value) / (100 - h1.value - h2.value)) + chiPhiNgoai.value).toFixed(2));
                        thuoc.soLuongHT = (khoiLuong.value * (100 - h1.value - h2.value)) / 100;
                        thuoc.tongTienHT = parseFloat((thuoc.donGiaHT * thuoc.soLuongHT).toFixed(2));
                        thuocSauXuLy.value.push(thuoc);
                        clear();
                      } else {
                        toast.add({
                          severity: 'error',
                          summary: 'Lỗi',
                          detail: "Vui lòng kiểm tra lại thông tin",
                          life: 2000
                        });
                      }
                    } else {
                      toast.add({
                        severity: 'error',
                        summary: 'Lỗi',
                        detail: "Vui lòng kiểm tra lại thông tin",
                        life: 2000
                      });
                    }
                  }
                }
              }
            }
            //
            // console.log("##########$$$$$$$$$$$$$$$$$$$$$$$ chonGiaiDoan h1: " + JSON.stringify(h1.value));
            // console.log("##########$$$$$$$$$$$$$$$$$$$$$$$ chonGiaiDoan h2: " + JSON.stringify(h2.value));
            // console.log("##########$$$$$$$$$$$$$$$$$$$$$$$ chonGiaiDoan dsTinhToan: " + JSON.stringify(dsTinhToan.value));
            console.log("##########$$$$$$$$$$$$$$$$$$$$$$$ chonGiaiDoan thuocSauXuLy: " + JSON.stringify(thuocSauXuLy.value));
          }
        }


      const clearSelect = () => {
        khoiLuong.value = null;
        donvi.value = "gam";
        giaiDoan.value = 0;
        thuchien.value = 0;
        chiPhiNgoai.value = null;
        donGia.value = null;
        //idThuoc.value = "";
        tyle.value = boPhanDung.value[0].label;
        tinhTrang.value = 1;
        tinhTrangBD.value = false;
        scBanDau.value = 0;
        clearThuoc();
      }
    const add = () => {
        const thuocxl = ref({} as ThuocXuLy);
        thuocxl.value.id = id.value;
        thuocDetails.value.filter(x => {if(x.id == idThuoc.value) {
          thuocxl.value.tenThuoc = x.tenThuoc;
          thuocxl.value.nguonGoc = x.nguonGoc;
          thuocxl.value.tenKhoaHoc = x.tenKhoaHoc;
        }});
        thuocxl.value.donViKL = donvi.value;
        thuocxl.value.soLuongBD = khoiLuong.value;
        thuocxl.value.donGiaNhap = donGia.value;
        thuocxl.value.chiPhiKhac = chiPhiNgoai.value;
        dsgiaiDoan.value.filter(x => {if(x.param == giaiDoan.value) thuocxl.value.yeuCauSD = x.label})
        if(!(giaiDoan.value == 3)&&(thuchien.value !=0))
        {
          const sc = ref([] as {label: string, value: string, param: number}[]);
          sc.value = dsSoChe.value.filter(x => x.param == thuchien.value);
          thuocxl.value.yeuCauSD = thuocxl.value.yeuCauSD + "- (" + sc.value[0].label + ")";
        }
        if(tinhTrang.value == 2)
        {
            thuocxl.value.tinhTrangDL = "Sơ chế";
            if(scBanDau.value > 0)
            {
              const sc = ref([] as {label: string, value: string, param: number}[]);
              sc.value = dsSoChe.value.filter(x => x.param == scBanDau.value);
              thuocxl.value.tinhTrangDL = thuocxl.value.tinhTrangDL + "- (" + sc.value[0].label + ")";
            }
            // thuocxl.value.tinhTrangDL = "Sơ chế - (" + scBanDau.value +")";
        }
        else if (tinhTrang.value == 1)
        {
            thuocxl.value.tinhTrangDL = "Thô";
        }
        else
        {
          thuocxl.value.tinhTrangDL = "Phức chế";
          if(scBanDau.value > 0)
          {
            const sc = ref([] as {label: string, value: string, param: number}[]);
            sc.value = dsSoChe.value.filter(x => x.param == scBanDau.value);
            thuocxl.value.tinhTrangDL = thuocxl.value.tinhTrangDL + "- (" + sc.value[0].value + ")";
          }
        }
        tinhToan(thuocxl.value);

    }
    const chonGiaiDoan = () => {
      soChe.value = [];
      const thuoc = ref({} as ThuocDetail);
      thuocDetails.value.filter(x => {if(x.id == idThuoc.value) thuoc.value = x});
        console.log("##########$$$$$$$$$$$$$$$$$$$$$$$ chonGiaiDoan giaiDoan: " + giaiDoan.value);
        const gd = ref("");
        dsgiaiDoan.value.forEach(x => {if(giaiDoan.value == x.param) gd.value = x.value;})
        dsSoCheBD.value.forEach(x => {
        if(x.value == gd.value) soChe.value.push({label: x.label, value: x.value, param: x.param});
      });
        console.log("##########$$$$$$$$$$$$$$$$$$$$$$$ chonGiaiDoan soChe: " + JSON.stringify(soChe.value));
    };
        const deleteFile = (i: number) =>
        {
            var index = thuocSauXuLy.value[thuocSauXuLy.value.findIndex(x => x.id == i)].id;

            console.log("##################@@@@@@@@@@@@@@@@@@@@@@ index id: " + index);
            thuocSauXuLy.value.splice(thuocSauXuLy.value.findIndex(x => x.id == i), 1);
            thuocSauXuLy.value.forEach(x => {if(x.id > index) x.id = x.id -1});
            id.value--;
        };
        const valid = () => {
            return (idThuoc.value);
        };

        const chonThuoc = () => {
          clearSelect();
          dsSoCheBD.value = [];
          // dsSoCheBD
          const thuoc = ref({} as ThuocDetail);
          thuocDetails.value.filter(x => {if(x.id == idThuoc.value) thuoc.value = x});
          dsSoChe.value.forEach(x => {
            dsSoCheBD.value.push({label: x.label, value: x.value, param: x.param});
          });
          dsSoCheBD.value.forEach(x => {
            if(x.label == "Ngâm, ủ, rửa, phơi, sấy") x.label = x.label + "(" + thuoc.value.ngam +"%)";
            else if(x.label == "Thái phiến") x.label = x.label + "(" + thuoc.value.thai +"%)";
            else if(x.label == "Sao vàng") x.label = x.label + "(" + thuoc.value.saoVang +"%)";
            else if(x.label == "Sao đen") x.label = x.label + "(" + thuoc.value.saoDen +"%)";
            else if(x.label == "Chích rượu, giấm, muối gừng, cam thảo, mật ong...") x.label = x.label + "(" + thuoc.value.chich +"%)";
          });
        }
      // const valid = computed(()=> khoiLuong.value&&donGia.value);

      const exportFile = () => {
            loadingBar.value = true;
            exportFileDetail.value = false;
            ThuocRepository.exportList(thuocSauXuLy.value)
                .then((response) => {
                    fileNameExport.value = response.data;
                    exportFileDetail.value = true;
                    loadingBar.value = false;
                    console.log("##########$$$$$$$$$$$$$$$$$$$$$$$ Thuoc: " + JSON.stringify(response.data));
                })
                .catch(err => {
                    loadingBar.value = false;
                    toast.add({
                        severity: 'error',
                        summary: 'Lỗi',
                        detail:err.response.data,
                        life: 2000
                    });
                });
        }
        const downloadFile = () => {
            axios({
                url: 'api/thuoc/taidanhsachthuoc/'+fileNameExport.value,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', fileNameExport.value);
                document.body.appendChild(fileLink);

                fileLink.click();
            })
                .catch(err => {
                    toast.add({
                        severity: 'error',
                        summary: 'Lỗi',
                        detail:err.response.data,
                        life: 2500
                    })});
        };
        const tinhTrangBanDau = () => {
          const thuoc = ref({} as ThuocDetail);
          dsSoCheBanDau.value = [];
          thuocDetails.value.filter(x => {if(x.id == idThuoc.value) thuoc.value = x});
            console.log("##########$$$$$$$$$$$$$$$$$$$$$$$ tinhTrangBanDau tinhTrang: " + tinhTrang.value);
            console.log("##########$$$$$$$$$$$$$$$$$$$$$$$ tinhTrangBanDau idThuoc: " + idThuoc.value);
            console.log("##########$$$$$$$$$$$$$$$$$$$$$$$ tinhTrangBanDau thuoc: " + JSON.stringify(thuoc.value));
          tinhTrangPhucChe.value = false;
          tinhTrangBD.value = false;
          dsgiaiDoan.value = dsgiaiDoan1.value.filter(x => (x.param > tinhTrang.value-1)||(x.param == tinhTrang.value-1));
          console.log("##########$$$$$$$$$$$$$$$$$$$$$$$ tinhTrangBanDau tinhTrang tinhTrang: " + JSON.stringify(tinhTrang.value));
          console.log("##########$$$$$$$$$$$$$$$$$$$$$$$ tinhTrangBanDau tinhTrang dsgiaiDoan1: " + JSON.stringify(dsgiaiDoan1.value));
          console.log("##########$$$$$$$$$$$$$$$$$$$$$$$ tinhTrangBanDau tinhTrang dsgiaiDoan: " + JSON.stringify(dsgiaiDoan.value));
            if(tinhTrang.value == 2)
            {
              // const dsSoChe = ref([
              //   {label: "Ngâm, ủ, rửa, phơi, sấy", value: "Sơ chế"},
              //   {label: "Thái phiến", value: "Sơ chế"},
              //   {label: "Sao vàng", value: "Phức chế"},
              //   {label: "Sao đen", value: "Phức chế"},
              //   {label: "Chích rượu, giấm, muối gừng, cam thảo, mật ong...", value: "Phức chế"},
              // ]);
                tinhTrangBD.value = true;
                console.log("##########$$$$$$$$$$$$$$$$$$$$$$$ tinhTrangBanDau tinhTrang 111111: ");
              dsSoCheBD.value.forEach(x => {
                  if(x.value == "Sơ chế") dsSoCheBanDau.value.push({label: x.label, value: x.value, param: x.param});
                });
                console.log("##########$$$$$$$$$$$$$$$$$$$$$$$ tinhTrangBanDau tinhTrang 111111 dsSoCheBanDau: " + JSON.stringify(dsSoCheBanDau.value));
                //dsgiaiDoan.value = dsgiaiDoan2.value;
            }
            else if(tinhTrang.value == 1)
            {
                console.log("##########$$$$$$$$$$$$$$$$$$$$$$$ tinhTrangBanDau tinhTrang 22222222: ");
                dsSoCheBanDau.value = [];
                dsgiaiDoan.value = dsgiaiDoan2.value;
                //dsgiaiDoan.value = dsgiaiDoan1.value;
            }
            // else {
            //   tinhTrangPhucChe.value = true;
            // }
        }
      return {
          idThuoc,
          thuocDetails,
          dsdonVi,
          khoiLuong,
          donvi,
          giaiDoan,
          dsgiaiDoan,
          soChe,
          chonGiaiDoan,
          thuchien,
          chiPhiNgoai,
          boPhanDung,
          baoQuan,
          add,
          donGia,
          tyle,
          thuocSauXuLy,
          deleteFile,
          valid,
          exportFile,
          downloadFile,
          loadingBar,
          exportFileDetail,
          dstinhTrang,
          tinhTrang,
          dsSoCheBanDau,
          scBanDau,
          tinhTrangBanDau,
          tinhTrangPhucChe,
          tinhTrangBD,
          chonThuoc,
      }
    }

  }
