import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import LoginPage from '../pages/LoginPage.vue';
import Home from "@/pages/Home.vue";
import ThuocCreatePage from "@/pages/ThuocCreatePage.vue";
import TinhChiTietPage from "@/pages/TinhChiTietPage.vue";
import ThongKePage from "@/pages/ThongKePage.vue";
import RegisterPage from "@/pages/RegisterPage.vue";
import UserList from "@/pages/UserList.vue";
import UserUpdatePage from "@/pages/UserUpdatePage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    props: true
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    props: true
  },
  {
    path: '/drugcreatepage',
    name: 'drugcreatepage',
    component: ThuocCreatePage,
    props: true
  },
  {
    path: '/DetailPage',
    name: 'detailpage',
    component: TinhChiTietPage,
    props: true
  },
  {
    path: '/statisticalpage',
    name: 'statisticalpage',
    component: ThongKePage,
    props: true
  },
  {
    path: '/registerpage',
    name: 'registerpage',
    component: RegisterPage,
    props: true
  },
  {
    path: '/userlist',
    name: 'userlist',
    component: UserList,
    props: true
  },
  {
    path: '/userupdate/:id',
    name: 'userupdate',
    component: UserUpdatePage,
    props: true
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
