

  import { computed, ref } from 'vue';
  import {useToast} from "primevue/usetoast";
  import moment from 'moment';
  import {FilterMatchMode, FilterOperator} from "primevue/api";
  import axios from "axios";
  import {ThuocCreate} from "@/models/thuocCreate";
  import ThuocRepository from "@/services/ThuocRepository";
  import {useStore} from "vuex";
  import router from "@/router";

  export default {
    setup() {
    const thuocCreate = ref({} as ThuocCreate);
    const toast = useToast();

    const clearThuoc = () => {
        const temp = ref({} as ThuocCreate);
        thuocCreate.value = temp.value;
    }
      const store = useStore();

      if(store.state.token == ""){
        router.push({
          name: 'home'
        });
      };

    const createThuoc = () => {
        ThuocRepository.createThuoc(thuocCreate.value)
            .then((response) => {
                clearThuoc();
                console.log("##########$$$$$$$$$$$$$$$$$$$$$$$ Thuoc: " + JSON.stringify(response.data))
                toast.add({
                    severity: 'success',
                    summary: 'Đăng ký',
                    detail: 'Đăng ký thành viên thành công vào hệ thống',
                    life: 2000
                });
            })
            .catch(err => {
                toast.add({
                    severity: 'error',
                    summary: 'Lỗi',
                    detail:err.response.data,
                    life: 2000
                });
            });
    }

      return {
          thuocCreate,
          createThuoc,
      }
    }

  }
